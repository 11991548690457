
import React from 'react';
import { Layout } from '@/components/layout/Layout';
import { Card } from '@/components/ui-components/Card';

const OurTeam = () => {
  return (
    <Layout>
      <section className="py-12 bg-gradient-to-r from-blue-50 to-green-50 dark:from-blue-950 dark:to-green-950">
        <div className="container px-4 md:px-6">
          <div className="flex flex-col items-center text-center mb-10">
            <h1 className="text-3xl md:text-4xl font-display font-bold mb-4">Our Team</h1>
            <p className="text-muted-foreground max-w-3xl">
              Meet the dedicated team behind St. Thomas Higher Secondary School
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-5xl mx-auto">
            <Card className="flex flex-col items-center text-center p-6 hover:shadow-lg transition-all">
              <div className="relative mb-6">
                <div className="h-40 w-40 rounded-full overflow-hidden">
                  <img 
                    src="/lovable-uploads/fc6297f4-cabe-4343-8e56-e612adb74466.png" 
                    alt="Mrs. Sobha Sreekumar" 
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="absolute -bottom-2 left-0 right-0 mx-auto w-fit px-4 py-1 bg-primary text-white text-xs rounded-full">
                  Leadership
                </div>
              </div>
              
              <h3 className="text-xl font-medium">Mrs. Sobha Sreekumar</h3>
              <p className="text-primary font-medium mt-1">Chairman of the Trust & Co-Founder</p>
              <p className="text-muted-foreground mt-4">
                Leading with vision and compassion to create an exceptional educational environment for our students.
              </p>
            </Card>
            
            <Card className="flex flex-col items-center text-center p-6 hover:shadow-lg transition-all">
              <div className="relative mb-6">
                <div className="h-40 w-40 rounded-full overflow-hidden">
                  <img 
                    src="/lovable-uploads/887e732a-5304-4a51-a4bb-b72dabc8aa08.png" 
                    alt="Mrs. Mini Aravind" 
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="absolute -bottom-2 left-0 right-0 mx-auto w-fit px-4 py-1 bg-secondary text-white text-xs rounded-full">
                  Academic
                </div>
              </div>
              
              <h3 className="text-xl font-medium">Mrs. Mini Aravind</h3>
              <p className="text-secondary font-medium mt-1">Principal</p>
              <p className="text-muted-foreground mt-4">
                Guiding our academic excellence with dedication and expertise to help every student achieve their potential.
              </p>
            </Card>
            
            <Card className="flex flex-col items-center text-center p-6 hover:shadow-lg transition-all">
              <div className="relative mb-6">
                <div className="h-40 w-40 rounded-full overflow-hidden bg-muted flex items-center justify-center">
                  <span className="text-3xl font-bold text-muted-foreground">R</span>
                </div>
                <div className="absolute -bottom-2 left-0 right-0 mx-auto w-fit px-4 py-1 bg-green-600 text-white text-xs rounded-full">
                  Administration
                </div>
              </div>
              
              <h3 className="text-xl font-medium">Rahul</h3>
              <p className="text-green-600 font-medium mt-1">Administrator</p>
              <p className="text-muted-foreground mt-4">
                Ensuring smooth day-to-day operations and administrative excellence to support our educational mission.
              </p>
            </Card>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default OurTeam;
