
import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { useGalleryAlbumDetails } from '@/hooks/useGalleryAlbumDetails';
import AlbumViewer from './AlbumViewer';
import { SkeletonCard } from '@/components/ui/skeleton-loader';
import { AlertTriangle } from 'lucide-react';

interface ImageGalleryProps {
  albumId: string;
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ albumId }) => {
  const { album, loading, error } = useGalleryAlbumDetails({ albumId, autoLoad: true });

  if (loading) {
    return (
      <div className="container mx-auto py-8">
        <div className="grid grid-cols-1 gap-6">
          <SkeletonCard height="h-96" />
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
            <SkeletonCard count={8} height="h-32" />
          </div>
        </div>
      </div>
    );
  }

  if (error || !album) {
    return (
      <Card className="bg-destructive/10 border-destructive/20">
        <CardContent className="pt-6 text-center">
          <AlertTriangle className="mx-auto h-10 w-10 text-destructive mb-2" />
          <h3 className="text-lg font-semibold">Failed to load album</h3>
          <p className="text-muted-foreground">{error?.message || 'Album not found'}</p>
        </CardContent>
      </Card>
    );
  }

  return (
    <div className="container mx-auto py-8">
      <h1 className="text-3xl font-bold mb-6">{album.title}</h1>
      {album.description && (
        <p className="text-muted-foreground mb-8">{album.description}</p>
      )}
      
      <AlbumViewer images={album.images} />
    </div>
  );
};

export default ImageGallery;
