
// Import the correct types
import { GalleryAlbum, GalleryImage, GalleryVideo, GalleryAlbumWithItems } from '@/types/gallery';

/**
 * Transforms a database gallery album to the application model
 */
export function transformGalleryAlbum(dbAlbum: any): GalleryAlbum {
  return {
    id: dbAlbum.id,
    title: dbAlbum.title,
    description: dbAlbum.description,
    coverImageUrl: dbAlbum.cover_image_url,
    itemCount: dbAlbum.item_count,
    createdAt: dbAlbum.created_at,
    updatedAt: dbAlbum.updated_at
  };
}

/**
 * Transforms a database gallery image to the application model
 */
export function transformGalleryImage(dbImage: any): GalleryImage {
  return {
    id: dbImage.id,
    albumId: dbImage.album_id,
    title: dbImage.title,
    description: dbImage.description,
    imageUrl: dbImage.image_url,
    thumbnailUrl: dbImage.thumbnail_url,
    sortOrder: dbImage.sort_order,
    createdAt: dbImage.created_at,
    updatedAt: dbImage.updated_at
  };
}

/**
 * Transforms a database gallery video to the application model
 */
export function transformGalleryVideo(dbVideo: any): GalleryVideo {
  return {
    id: dbVideo.id,
    title: dbVideo.title,
    description: dbVideo.description,
    videoUrl: dbVideo.video_url,
    thumbnailUrl: dbVideo.thumbnail_url,
    duration: dbVideo.duration,
    createdAt: dbVideo.created_at,
    updatedAt: dbVideo.updated_at
  };
}

/**
 * Transforms a database gallery album with its images to the application model
 */
export function transformGalleryAlbumWithImages(dbAlbum: any, dbImages: any[]): GalleryAlbumWithItems {
  const album = transformGalleryAlbum(dbAlbum);
  const images = dbImages.map(transformGalleryImage);
  
  return {
    ...album,
    images
  };
}
