
import { useQuery } from '@tanstack/react-query';
import { fetchGalleryVideos } from '@/services/galleryService';
import { GalleryVideo } from '@/types/gallery';
import { toast } from 'sonner';

interface UseGalleryVideosOptions {
  limit?: number;
  page?: number;
}

interface UseGalleryVideosResult {
  videos: GalleryVideo[];
  isLoading: boolean;
  error: Error | null;
  refetch: () => Promise<void>;
  hasMore: boolean;
  totalCount: number;
}

export function useGalleryVideos(options: UseGalleryVideosOptions = {}): UseGalleryVideosResult {
  const { limit = 12, page = 1 } = options;
  
  const {
    data,
    isLoading,
    error,
    refetch: refetchQuery
  } = useQuery({
    queryKey: ['videos', { limit, page }],
    queryFn: () => fetchGalleryVideos({ limit, page }),
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 10 * 60 * 1000, // 10 minutes
    retry: 1,
    meta: {
      onError: (err: Error) => {
        console.error('Failed to load gallery videos:', err);
        toast.error('Failed to load videos', {
          description: "There was an error loading the gallery videos."
        });
      }
    }
  });

  const refetch = async () => {
    await refetchQuery();
  };

  return { 
    videos: data?.videos || [], 
    isLoading, 
    error: error as Error | null,
    refetch,
    hasMore: data?.hasMore || false,
    totalCount: data?.totalCount || 0
  };
}
