
import React, { useState, useCallback } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

interface GalleryTabsProps {
  defaultValue: string;
  tabs: Array<{
    value: string;
    label: string;
    icon?: React.ReactNode;
  }>;
  onTabChange?: (value: string) => void;
  children: React.ReactNode;
}

export const GalleryTabs = React.memo(function GalleryTabs({
  defaultValue,
  tabs,
  onTabChange,
  children
}: GalleryTabsProps) {
  const handleValueChange = useCallback((value: string) => {
    if (onTabChange) {
      onTabChange(value);
    }
  }, [onTabChange]);

  return (
    <Tabs 
      defaultValue={defaultValue} 
      className="w-full"
      onValueChange={handleValueChange}
    >
      <div className="flex justify-center mb-6">
        <TabsList className={`grid w-full max-w-md grid-cols-${tabs.length}`}>
          {tabs.map((tab) => (
            <TabsTrigger 
              key={tab.value} 
              value={tab.value}
              className="flex items-center gap-2"
            >
              {tab.icon}
              <span>{tab.label}</span>
            </TabsTrigger>
          ))}
        </TabsList>
      </div>
      
      {children}
    </Tabs>
  );
});
