
import { lazy, Suspense } from 'react';
import { RouteConfig } from './types';

// Loading component for Suspense
const Loading = () => <div className="flex items-center justify-center p-8">Loading...</div>;

// Admin Routes
const RoleManagement = lazy(() => import('@/pages/admin/RoleManagement'));
const UserManagement = lazy(() => import('@/pages/admin/UserManagement'));
const UserRegistration = lazy(() => import('@/pages/admin/UserRegistration'));
const AdmissionApplications = lazy(() => import('@/pages/admin/AdmissionApplications'));
const AdmissionApplicationDetail = lazy(() => import('@/pages/admin/AdmissionApplicationDetail'));
const GalleryManagement = lazy(() => import('@/pages/admin/GalleryManagement'));

export const adminRoutes: RouteConfig[] = [
  {
    path: '/admin/role-management',
    element: <Suspense fallback={<Loading />}><RoleManagement /></Suspense>,
    requiresAuth: true,
    allowedRoles: ['admin'],
  },
  {
    path: '/admin/user-management',
    element: <Suspense fallback={<Loading />}><UserManagement /></Suspense>,
    requiresAuth: true,
    allowedRoles: ['admin'],
  },
  {
    path: '/admin/user-registration',
    element: <Suspense fallback={<Loading />}><UserRegistration /></Suspense>,
    requiresAuth: true,
    allowedRoles: ['admin'],
  },
  {
    path: '/admin/admissions',
    element: <Suspense fallback={<Loading />}><AdmissionApplications /></Suspense>,
    requiresAuth: true,
    allowedRoles: ['admin'],
  },
  {
    path: '/admin/admissions/:id',
    element: <Suspense fallback={<Loading />}><AdmissionApplicationDetail /></Suspense>,
    requiresAuth: true,
    allowedRoles: ['admin'],
  },
  {
    path: '/admin/gallery',
    element: <Suspense fallback={<Loading />}><GalleryManagement /></Suspense>,
    requiresAuth: true,
    allowedRoles: ['admin'],
  },
];
