
import React, { useEffect, useState } from 'react';
import { X, ChevronLeft, ChevronRight, Volume2, VolumeX } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { getVideoUrl } from '@/services/galleryService';
import { GalleryVideo } from '@/types/gallery';
import { AspectRatio } from '@/components/ui/aspect-ratio';
import { getYouTubeEmbedUrl } from '@/utils/videoUtils';

interface FullScreenVideoViewerProps {
  videos: GalleryVideo[];
  initialIndex: number;
  onClose: () => void;
}

export function FullScreenVideoViewer({ 
  videos, 
  initialIndex = 0, 
  onClose 
}: FullScreenVideoViewerProps) {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [videoError, setVideoError] = useState<Record<string, boolean>>({});
  const [isMuted, setIsMuted] = useState(false);
  
  const currentVideo = videos[currentIndex];
  
  const goToNext = () => {
    setCurrentIndex((prev) => (prev === videos.length - 1 ? 0 : prev + 1));
  };
  
  const goToPrevious = () => {
    setCurrentIndex((prev) => (prev === 0 ? videos.length - 1 : prev - 1));
  };
  
  const toggleMute = () => {
    setIsMuted(prev => !prev);
  };
  
  const handleVideoError = () => {
    setVideoError(prev => ({
      ...prev,
      [currentIndex]: true
    }));
  };
  
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        goToNext();
      } else if (event.key === 'ArrowLeft') {
        goToPrevious();
      } else if (event.key === 'Escape') {
        onClose();
      } else if (event.key === 'm') {
        toggleMute();
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    
    // Lock body scroll
    document.body.style.overflow = 'hidden';
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      // Restore body scroll
      document.body.style.overflow = '';
    };
  }, [videos.length, onClose]);
  
  if (!currentVideo) return null;
  
  const isYouTubeVideo = currentVideo.videoUrl.includes('youtube.com') || currentVideo.videoUrl.includes('youtu.be');
  
  return (
    <div className="fixed inset-0 z-50 bg-black/95 flex flex-col">
      {/* Header */}
      <div className="flex justify-between items-center px-4 py-3 bg-black/50">
        <div className="text-white">
          <p className="text-sm font-medium">{currentVideo.title}</p>
          <p className="text-xs text-gray-400">{currentIndex + 1} of {videos.length}</p>
        </div>
        <Button 
          variant="ghost" 
          size="icon" 
          className="text-white hover:bg-white/20"
          onClick={onClose}
        >
          <X className="h-5 w-5" />
        </Button>
      </div>
      
      {/* Video Container */}
      <div className="flex-1 flex items-center justify-center relative">
        {videoError[currentIndex] ? (
          <div className="text-white text-center">
            <p>Failed to load video</p>
          </div>
        ) : (
          <div className="w-full max-w-4xl">
            <AspectRatio ratio={16 / 9} className="bg-black">
              {isYouTubeVideo ? (
                <iframe
                  src={`${getYouTubeEmbedUrl(currentVideo.videoUrl)}${isMuted ? '?mute=1' : ''}`}
                  title={currentVideo.title}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="w-full h-full"
                  onError={handleVideoError}
                />
              ) : (
                <video
                  src={getVideoUrl(currentVideo.videoUrl)}
                  title={currentVideo.title}
                  controls
                  muted={isMuted}
                  autoPlay
                  className="w-full h-full object-contain"
                  onError={handleVideoError}
                />
              )}
            </AspectRatio>
          </div>
        )}
        
        {/* Navigation Buttons */}
        <Button 
          variant="ghost" 
          size="icon" 
          className="absolute left-4 h-10 w-10 rounded-full bg-black/50 text-white hover:bg-black/70"
          onClick={goToPrevious}
        >
          <ChevronLeft className="h-6 w-6" />
        </Button>
        
        <Button 
          variant="ghost" 
          size="icon" 
          className="absolute right-4 h-10 w-10 rounded-full bg-black/50 text-white hover:bg-black/70"
          onClick={goToNext}
        >
          <ChevronRight className="h-6 w-6" />
        </Button>
        
        {/* Mute Button - Only for non-YouTube videos */}
        {!isYouTubeVideo && (
          <Button
            variant="ghost"
            size="icon"
            className="absolute bottom-4 right-4 h-10 w-10 rounded-full bg-black/50 text-white hover:bg-black/70"
            onClick={toggleMute}
          >
            {isMuted ? (
              <VolumeX className="h-5 w-5" />
            ) : (
              <Volume2 className="h-5 w-5" />
            )}
          </Button>
        )}
      </div>
    </div>
  );
}
