
import React, { useEffect } from 'react';
import { toast } from 'sonner';
import { ErrorBoundary } from './ErrorBoundary';
import { useGlobalState } from '@/context/GlobalStateContext';

interface GlobalErrorHandlerProps {
  children: React.ReactNode;
}

export const GlobalErrorHandler: React.FC<GlobalErrorHandlerProps> = ({ children }) => {
  // Use try/catch to protect against context not being available
  let addNotification;
  try {
    const globalState = useGlobalState();
    addNotification = globalState?.addNotification;
  } catch (error) {
    console.warn('GlobalStateContext not available:', error);
    // Provide a no-op function as fallback
    addNotification = () => {};
  }
  
  // Handle unhandled promise rejections
  useEffect(() => {
    const handleUnhandledRejection = (event: PromiseRejectionEvent) => {
      console.error('Unhandled Promise Rejection:', event.reason);
      
      // Add to global notification system if available
      addNotification?.({
        type: 'error',
        message: event.reason?.message || 'An unexpected error occurred',
      });
      
      // Display error to user
      toast.error('An unexpected error occurred. Please try again or contact support.', {
        description: event.reason?.message || 'Unknown error',
        duration: 5000,
      });

      // Prevent the default browser behavior that shows an uncaught error in console
      event.preventDefault();
    };

    // Listen for unhandled rejections
    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    // Listen for errors
    const handleError = (event: ErrorEvent) => {
      console.error('Global error:', event.error || event.message);
      
      // Add to global notification system if available
      addNotification?.({
        type: 'error',
        message: event.message || 'An unexpected error occurred',
      });
      
      // Don't show toast for network errors or CORS issues to avoid too many notifications
      if (!event.message.includes('Network') && !event.message.includes('CORS')) {
        toast.error('An error occurred', {
          description: event.message || 'Unknown error',
          duration: 5000,
        });
      }
    };

    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
      window.removeEventListener('error', handleError);
    };
  }, [addNotification]);

  return <ErrorBoundary>{children}</ErrorBoundary>;
};
