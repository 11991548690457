import React from 'react';
import { Layout } from '@/components/layout/Layout';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '@/components/ui-components/Card';
import { Badge } from '@/components/ui-components/Badge';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { useAuth } from '@/context/AuthContext';
import { 
  User, 
  Mail, 
  Phone, 
  MapPin, 
  Calendar, 
  BookOpen,
  Edit
} from 'lucide-react';

const Profile = () => {
  const { user } = useAuth();

  // Role-specific content is already handled in the existing code
  // ... keep existing code (getRoleSpecificContent function)

  // Generate role-specific content
  const getRoleSpecificContent = () => {
    if (!user) return null;

    switch (user.role) {
      case 'teacher':
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <Card>
              <CardHeader>
                <CardTitle>Classes</CardTitle>
                <CardDescription>Classes you are currently teaching</CardDescription>
              </CardHeader>
              <CardContent>
                <ul className="space-y-3">
                  <li className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <BookOpen className="h-4 w-4 text-primary" />
                      <span>Mathematics 101</span>
                    </div>
                    <Badge>28 students</Badge>
                  </li>
                  <li className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <BookOpen className="h-4 w-4 text-primary" />
                      <span>Physics Fundamentals</span>
                    </div>
                    <Badge>24 students</Badge>
                  </li>
                  <li className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <BookOpen className="h-4 w-4 text-primary" />
                      <span>Computer Science</span>
                    </div>
                    <Badge>32 students</Badge>
                  </li>
                </ul>
              </CardContent>
            </Card>
            
            <Card>
              <CardHeader>
                <CardTitle>Schedule</CardTitle>
                <CardDescription>Your upcoming classes this week</CardDescription>
              </CardHeader>
              <CardContent>
                <ul className="space-y-3">
                  <li className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <Calendar className="h-4 w-4 text-primary" />
                      <div>
                        <p className="font-medium">Mathematics 101</p>
                        <p className="text-sm text-muted-foreground">Monday, 10:30 AM - 12:00 PM</p>
                      </div>
                    </div>
                  </li>
                  <li className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <Calendar className="h-4 w-4 text-primary" />
                      <div>
                        <p className="font-medium">Physics Fundamentals</p>
                        <p className="text-sm text-muted-foreground">Tuesday, 1:00 PM - 2:30 PM</p>
                      </div>
                    </div>
                  </li>
                  <li className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <Calendar className="h-4 w-4 text-primary" />
                      <div>
                        <p className="font-medium">Computer Science</p>
                        <p className="text-sm text-muted-foreground">Wednesday, 3:30 PM - 5:00 PM</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </CardContent>
            </Card>
          </div>
        );
      
      case 'student':
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <Card>
              <CardHeader>
                <CardTitle>Enrolled Courses</CardTitle>
                <CardDescription>Courses you are currently taking</CardDescription>
              </CardHeader>
              <CardContent>
                <ul className="space-y-3">
                  <li className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <BookOpen className="h-4 w-4 text-primary" />
                      <span>Mathematics 101</span>
                    </div>
                    <Badge>B+</Badge>
                  </li>
                  <li className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <BookOpen className="h-4 w-4 text-primary" />
                      <span>Physics Fundamentals</span>
                    </div>
                    <Badge>A-</Badge>
                  </li>
                  <li className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <BookOpen className="h-4 w-4 text-primary" />
                      <span>Computer Science</span>
                    </div>
                    <Badge>B</Badge>
                  </li>
                </ul>
              </CardContent>
            </Card>
            
            <Card>
              <CardHeader>
                <CardTitle>Academic Progress</CardTitle>
                <CardDescription>Your progress in the current semester</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <div>
                    <div className="flex justify-between mb-1">
                      <span className="text-sm font-medium">Credits Completed</span>
                      <span className="text-sm font-medium">18/24</span>
                    </div>
                    <div className="w-full bg-secondary rounded-full h-2.5">
                      <div className="bg-primary h-2.5 rounded-full" style={{ width: '75%' }}></div>
                    </div>
                  </div>
                  
                  <div>
                    <div className="flex justify-between mb-1">
                      <span className="text-sm font-medium">GPA</span>
                      <span className="text-sm font-medium">3.7/4.0</span>
                    </div>
                    <div className="w-full bg-secondary rounded-full h-2.5">
                      <div className="bg-primary h-2.5 rounded-full" style={{ width: '92.5%' }}></div>
                    </div>
                  </div>
                  
                  <div>
                    <div className="flex justify-between mb-1">
                      <span className="text-sm font-medium">Attendance</span>
                      <span className="text-sm font-medium">95%</span>
                    </div>
                    <div className="w-full bg-secondary rounded-full h-2.5">
                      <div className="bg-primary h-2.5 rounded-full" style={{ width: '95%' }}></div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        );
      
      case 'admin':
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <Card>
              <CardHeader>
                <CardTitle>Admin Privileges</CardTitle>
                <CardDescription>Your system access levels</CardDescription>
              </CardHeader>
              <CardContent>
                <ul className="space-y-2">
                  <li className="flex items-center gap-2 text-sm">
                    <Badge variant="success">Access</Badge>
                    <span>User Management</span>
                  </li>
                  <li className="flex items-center gap-2 text-sm">
                    <Badge variant="success">Access</Badge>
                    <span>Course Management</span>
                  </li>
                  <li className="flex items-center gap-2 text-sm">
                    <Badge variant="success">Access</Badge>
                    <span>System Configuration</span>
                  </li>
                  <li className="flex items-center gap-2 text-sm">
                    <Badge variant="success">Access</Badge>
                    <span>Reports & Analytics</span>
                  </li>
                  <li className="flex items-center gap-2 text-sm">
                    <Badge variant="success">Access</Badge>
                    <span>Announcements</span>
                  </li>
                </ul>
              </CardContent>
            </Card>
            
            <Card>
              <CardHeader>
                <CardTitle>Recent Activity</CardTitle>
                <CardDescription>Your latest administrative actions</CardDescription>
              </CardHeader>
              <CardContent>
                <ul className="space-y-3">
                  <li className="text-sm">
                    <p className="font-medium">Generated monthly attendance report</p>
                    <p className="text-muted-foreground">October 12, 2023 - 10:45 AM</p>
                  </li>
                  <li className="text-sm">
                    <p className="font-medium">Created new teacher account: Maria Thompson</p>
                    <p className="text-muted-foreground">October 11, 2023 - 2:30 PM</p>
                  </li>
                  <li className="text-sm">
                    <p className="font-medium">Updated system preferences</p>
                    <p className="text-muted-foreground">October 10, 2023 - 9:15 AM</p>
                  </li>
                </ul>
              </CardContent>
            </Card>
          </div>
        );
      
      default:
        return <div>Unknown role</div>;
    }
  };

  return (
    <Layout>
      <div className="container px-4 md:px-6 py-10 animate-fade-in">
        <div className="flex flex-col md:flex-row gap-8 mb-8">
          {/* Profile Card */}
          <Card className="md:w-1/3">
            <CardContent className="flex flex-col items-center pt-6">
              <div className="relative">
                <Avatar className="h-24 w-24 mb-4 rounded-full">
                  <AvatarImage 
                    src={user?.avatarUrl || "https://i.pravatar.cc/150?u=default"} 
                    alt={user?.name} 
                  />
                  <AvatarFallback className="text-2xl">
                    {user?.name.split(' ').map(n => n[0]).join('')}
                  </AvatarFallback>
                </Avatar>
                <button className="absolute bottom-4 right-0 bg-primary text-primary-foreground rounded-full p-1">
                  <Edit className="h-4 w-4" />
                </button>
              </div>
              <h2 className="text-xl font-bold">{user?.name}</h2>
              
              {user?.role === 'teacher' && (
                <Badge className="mt-2">Teacher</Badge>
              )}
              {user?.role === 'student' && (
                <Badge className="mt-2">Student</Badge>
              )}
              {user?.role === 'admin' && (
                <Badge className="mt-2">Administrator</Badge>
              )}
              
              <Separator className="my-6" />
              
              <div className="w-full space-y-4">
                <div className="flex items-center gap-2">
                  <Mail className="h-4 w-4 text-muted-foreground" />
                  <span className="text-sm">{user?.email}</span>
                </div>
                
                <div className="flex items-center gap-2">
                  <Phone className="h-4 w-4 text-muted-foreground" />
                  <span className="text-sm">+1 (555) 123-4567</span>
                </div>
                
                <div className="flex items-center gap-2">
                  <MapPin className="h-4 w-4 text-muted-foreground" />
                  <span className="text-sm">123 Education Ave, Learning City</span>
                </div>
                
                <div className="flex items-center gap-2">
                  <Calendar className="h-4 w-4 text-muted-foreground" />
                  <span className="text-sm">Joined: January 2023</span>
                </div>
              </div>
              
              <Button className="w-full mt-6">Edit Profile</Button>
            </CardContent>
          </Card>
          
          {/* Profile Content */}
          <div className="md:w-2/3 space-y-6">
            <Card>
              <CardHeader>
                <CardTitle>About Me</CardTitle>
                <CardDescription>Your personal information</CardDescription>
              </CardHeader>
              <CardContent>
                <p className="text-muted-foreground">
                  {user?.role === 'teacher' && "Experienced educator with a passion for helping students excel. Specialized in mathematics and sciences with over 10 years of teaching experience. Dedicated to creating an engaging and supportive learning environment."}
                  {user?.role === 'student' && "Dedicated student pursuing a degree in Computer Science. Interested in artificial intelligence and software development. Active participant in campus activities and maintaining a strong academic record."}
                  {user?.role === 'admin' && "Experienced educational administrator with expertise in school management and policy implementation. Focused on creating efficient systems that support both teachers and students while maintaining educational excellence."}
                </p>
              </CardContent>
            </Card>
            
            {/* Role-specific content */}
            {getRoleSpecificContent()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
