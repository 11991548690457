
import { lazy, Suspense } from 'react';
import Login from '@/pages/Login';
import Profile from '@/pages/Profile';
import { RouteConfig } from './types';

// Loading component for Suspense
const Loading = () => <div className="flex items-center justify-center p-8">Loading...</div>;

// Dashboard
const Dashboard = lazy(() => import('@/pages/Dashboard'));

export const authRoutes: RouteConfig[] = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/dashboard',
    element: <Suspense fallback={<Loading />}><Dashboard /></Suspense>,
    requiresAuth: true,
  },
  {
    path: '/profile',
    element: <Profile />,
    requiresAuth: true,
  },
];
