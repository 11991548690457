
import React, { useEffect } from 'react';
import { Layout } from '@/components/layout/Layout';
import { LoginForm } from '@/components/auth/LoginForm';
import { Toaster } from 'sonner';
import { useAuth } from '@/context/AuthContext';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

const Login = () => {
  const { isAuthenticated, loading } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  
  // Get the location they were trying to access before being redirected to login
  const from = location.state?.from?.pathname || '/dashboard';

  // Add debugging for login page rendering
  useEffect(() => {
    console.log('Login page rendered, auth state:', { isAuthenticated, loading });
    console.log('Redirect target after login:', from);
  }, [isAuthenticated, loading, from]);

  // If already authenticated, redirect to dashboard or the page they were trying to access
  if (!loading && isAuthenticated) {
    console.log('User already authenticated, redirecting to:', from);
    return <Navigate to={from} replace />;
  }

  return (
    <Layout>
      <Toaster position="top-right" richColors />
      <section className="py-12">
        <div className="container px-4 md:px-6">
          <div className="mx-auto max-w-md text-center mb-8">
            <h1 className="text-3xl font-bold mb-2">School Login</h1>
            <p className="text-muted-foreground">
              Login with your school ID (e.g., STS202501) or email address
            </p>
          </div>
          <LoginForm />
        </div>
      </section>
    </Layout>
  );
};

export default Login;
