
import React, { useState, useCallback } from 'react';
import { Layout } from '@/components/layout/Layout';
import { TabsContent } from '@/components/ui/tabs';
import { Image, Video } from 'lucide-react';
import ImageGallery from '@/components/gallery/ImageGallery';
import { VideoGallery } from '@/components/gallery/VideoGallery';
import { GalleryHeader } from '@/components/gallery/GalleryHeader';
import { GalleryTabs } from '@/components/gallery/GalleryTabs';
import { AlbumGrid } from '@/components/gallery/AlbumGrid';
import { useGalleryAlbums } from '@/hooks/useGalleryAlbums';
import { Button } from '@/components/ui/button';
import { GalleryPagination } from '@/components/gallery/GalleryPagination';

const ALBUMS_PER_PAGE = 9;

const Gallery = () => {
  const [activeTab, setActiveTab] = useState<'images' | 'videos'>('images');
  const [selectedAlbumId, setSelectedAlbumId] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  
  // Fetch albums with pagination
  const { albums, isLoading, totalCount } = useGalleryAlbums({
    limit: ALBUMS_PER_PAGE,
    page: currentPage
  });
  
  const totalPages = Math.ceil(totalCount / ALBUMS_PER_PAGE);
  
  const handleTabChange = useCallback((value: string) => {
    setActiveTab(value as 'images' | 'videos');
    setSelectedAlbumId(null);
  }, []);

  const handleAlbumClick = useCallback((albumId: string) => {
    setSelectedAlbumId(albumId);
  }, []);

  const handleBackToAlbums = useCallback(() => {
    setSelectedAlbumId(null);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const galleryTabs = [
    { value: 'images', label: 'Images', icon: <Image className="h-4 w-4" /> },
    { value: 'videos', label: 'Videos', icon: <Video className="h-4 w-4" /> }
  ];

  return (
    <Layout>
      <div className="container py-8 px-4 animate-fade-in">
        <div className="max-w-6xl mx-auto">
          <GalleryHeader 
            title="School Gallery"
            description="Explore photos and videos from our school events and activities"
          />

          <GalleryTabs 
            defaultValue="images" 
            tabs={galleryTabs}
            onTabChange={handleTabChange}
          >
            <TabsContent value="images" className="mt-4">
              {selectedAlbumId ? (
                <>
                  <Button 
                    variant="ghost" 
                    className="mb-6"
                    onClick={handleBackToAlbums}
                  >
                    ← Back to albums
                  </Button>
                  <ImageGallery albumId={selectedAlbumId} />
                </>
              ) : (
                <>
                  <AlbumGrid 
                    albums={albums} 
                    onAlbumClick={handleAlbumClick}
                    isLoading={isLoading}
                  />
                  
                  <div className="mt-8 flex justify-center">
                    <GalleryPagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </>
              )}
            </TabsContent>

            <TabsContent value="videos" className="mt-4">
              <VideoGallery />
            </TabsContent>
          </GalleryTabs>
        </div>
      </div>
    </Layout>
  );
};

export default Gallery;
