
import { lazy, Suspense } from 'react';
import { RouteConfig } from './types';

// Loading component for Suspense
const Loading = () => <div className="flex items-center justify-center p-8">Loading...</div>;

// Fee Portal Routes
const FeePortal = lazy(() => import('@/pages/FeePortal'));
const StudentFeeLogin = lazy(() => import('@/pages/feeportal/StudentFeeLogin'));
const CashierLogin = lazy(() => import('@/pages/feeportal/CashierLogin'));
const StudentFeeDashboard = lazy(() => import('@/pages/feeportal/StudentFeeDashboard'));
const CashierDashboard = lazy(() => import('@/pages/feeportal/CashierDashboard'));

export const feeRoutes: RouteConfig[] = [
  {
    path: '/fee-portal',
    element: <Suspense fallback={<Loading />}><FeePortal /></Suspense>,
  },
  {
    path: '/fee-portal/student-login',
    element: <Suspense fallback={<Loading />}><StudentFeeLogin /></Suspense>,
  },
  {
    path: '/fee-portal/cashier-login',
    element: <Suspense fallback={<Loading />}><CashierLogin /></Suspense>,
  },
  {
    path: '/fee-portal/student-dashboard',
    element: <Suspense fallback={<Loading />}><StudentFeeDashboard /></Suspense>,
    requiresAuth: true,
  },
  {
    path: '/fee-portal/cashier-dashboard',
    element: <Suspense fallback={<Loading />}><CashierDashboard /></Suspense>,
    requiresAuth: true,
  },
];
