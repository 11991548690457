
import { Session } from '@supabase/supabase-js';
import { supabase } from '@/integrations/supabase/client';
import { UserProfile, UserRole } from '@/types/auth';
import { handleDatabaseError } from '@/utils/supabaseErrorHandler';

/**
 * Transforms Supabase user data into UserProfile format
 */
export const transformUserData = async (session: Session | null): Promise<UserProfile | null> => {
  if (!session?.user) {
    console.log('No user in session, returning null profile');
    return null;
  }
  
  try {
    console.log('Transforming user data for user ID:', session.user.id);
    
    // Fetch the user's profile from our profiles table
    const { data: profile, error } = await supabase
      .from('profiles')
      .select('*')
      .eq('id', session.user.id)
      .single();
    
    if (error) {
      console.error('Error fetching profile:', error);
      handleDatabaseError(error, 'Error fetching user profile');
      return null;
    }

    if (!profile) {
      console.error('No profile found for user:', session.user.id);
      return null;
    }

    const userProfile: UserProfile = {
      id: session.user.id,
      email: profile.email || session.user.email || '',
      firstName: profile.first_name || '',
      lastName: profile.last_name || '',
      name: `${profile.first_name || ''} ${profile.last_name || ''}`.trim() || session.user.email?.split('@')[0] || 'User',
      role: (profile.role as UserRole) || 'student',
      avatarUrl: profile.avatar_url || undefined,
      uniqueId: profile.unique_id || '',
      createdAt: profile.created_at || new Date().toISOString(),
      updatedAt: profile.updated_at || new Date().toISOString()
    };
    
    console.log('User profile transformed:', userProfile.name, userProfile.role, userProfile.uniqueId);
    return userProfile;
  } catch (error) {
    console.error('Error transforming user data:', error);
    return null;
  }
};

/**
 * Checks if the current session is valid
 */
export const isSessionValid = (session: Session | null): boolean => {
  if (!session) return false;
  
  try {
    // Check if the session has expired
    const expiresAt = session.expires_at;
    if (!expiresAt) return false;
    
    // Convert expires_at to milliseconds (it's in seconds) and compare with current time
    const expiryTime = expiresAt * 1000;
    const now = Date.now();
    
    return expiryTime > now;
  } catch (error) {
    console.error('Error validating session:', error);
    return false;
  }
};
