
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

type Announcement = {
  id: string;
  message: string;
  is_active: boolean;
  created_at: string;
  updated_at: string;
};

interface AnnouncementContextType {
  announcement: Announcement | null;
  loading: boolean;
  error: Error | null;
  updateAnnouncement: (id: string, message: string, is_active: boolean) => Promise<void>;
  fetchAnnouncements: () => Promise<void>;
}

const AnnouncementContext = createContext<AnnouncementContextType | undefined>(undefined);

const fetchAnnouncements = async (): Promise<Announcement | null> => {
  const { data, error } = await supabase
    .from('site_announcements')
    .select('*')
    .order('updated_at', { ascending: false });
  
  if (error) {
    throw error;
  }

  // Find the active announcement, or use the most recent one if none are active
  return (data.find(ann => ann.is_active) || data[0] || null);
};

export const AnnouncementProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient();
  
  const { data: announcement, isLoading: loading, error, refetch } = useQuery({
    queryKey: ['siteAnnouncement'],
    queryFn: fetchAnnouncements,
    staleTime: 1000 * 60 * 5, // 5 minutes
    gcTime: 1000 * 60 * 10, // 10 minutes
  });

  const updateAnnouncementMutation = useMutation({
    mutationFn: async ({ id, message, is_active }: { id: string, message: string, is_active: boolean }) => {
      const { data, error } = await supabase
        .from('site_announcements')
        .update({ message, is_active, updated_at: new Date().toISOString() })
        .eq('id', id)
        .select()
        .single();
      
      if (error) {
        throw error;
      }
      
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['siteAnnouncement'] });
      toast.success('Announcement updated successfully');
    },
    onError: (err: Error) => {
      console.error('Error updating announcement:', err);
      toast.error('Failed to update announcement');
    }
  });

  const updateAnnouncement = async (id: string, message: string, is_active: boolean): Promise<void> => {
    await updateAnnouncementMutation.mutateAsync({ id, message, is_active });
  };

  const fetchAnnouncementsHandler = async (): Promise<void> => {
    await refetch();
  };

  return (
    <AnnouncementContext.Provider
      value={{
        announcement: announcement || null,
        loading,
        error: error as Error | null,
        updateAnnouncement,
        fetchAnnouncements: fetchAnnouncementsHandler
      }}
    >
      {children}
    </AnnouncementContext.Provider>
  );
};

export const useAnnouncement = () => {
  const context = useContext(AnnouncementContext);
  if (context === undefined) {
    throw new Error('useAnnouncement must be used within an AnnouncementProvider');
  }
  return context;
};
