
import Index from '@/pages/Index';
import AboutUs from '@/pages/AboutUs';
import SchoolDetails from '@/pages/about/SchoolDetails';
import OurTeam from '@/pages/about/OurTeam';
import Gallery from '@/pages/Gallery';
import Notice from '@/pages/Notice';
import OnlineAdmissions from '@/pages/OnlineAdmissions';
import AdmissionForm from '@/pages/AdmissionForm';
import Syllabus from '@/pages/Syllabus';
import PrincipalsMessage from '@/pages/PrincipalsMessage';
import ChairmansMessage from '@/pages/ChairmansMessage';
import NotFound from '@/pages/NotFound';
import { RouteConfig } from './types';

export const publicRoutes: RouteConfig[] = [
  {
    path: '/',
    element: <Index />,
  },
  {
    path: '/about-us',
    element: <AboutUs />,
  },
  {
    path: '/about-us/school-details',
    element: <SchoolDetails />,
  },
  {
    path: '/about-us/our-team',
    element: <OurTeam />,
  },
  {
    path: '/gallery',
    element: <Gallery />,
  },
  {
    path: '/notice',
    element: <Notice />,
  },
  {
    path: '/online-admissions',
    element: <OnlineAdmissions />,
  },
  {
    path: '/admission-form',
    element: <AdmissionForm />,
  },
  {
    path: '/syllabus',
    element: <Syllabus />,
  },
  {
    path: '/principals-message',
    element: <PrincipalsMessage />,
  },
  {
    path: '/chairmans-message',
    element: <ChairmansMessage />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
