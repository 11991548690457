
import { lazy, Suspense } from 'react';
import { RouteConfig } from './types';

// Loading component for Suspense
const Loading = () => <div className="flex items-center justify-center p-8">Loading...</div>;

// Inventory Routes
const InventoryLogin = lazy(() => import('@/pages/inventory/InventoryLogin'));
const InventoryDashboard = lazy(() => import('@/pages/inventory/InventoryDashboard'));
const InventoryList = lazy(() => import('@/pages/inventory/InventoryList'));
const AddInventoryItem = lazy(() => import('@/pages/inventory/AddInventoryItem'));
const EditInventoryItem = lazy(() => import('@/pages/inventory/EditInventoryItem'));
const InventoryItemDetail = lazy(() => import('@/pages/inventory/InventoryItemDetail'));

export const inventoryRoutes: RouteConfig[] = [
  {
    path: '/inventory/login',
    element: <Suspense fallback={<Loading />}><InventoryLogin /></Suspense>,
  },
  {
    path: '/inventory/dashboard',
    element: <Suspense fallback={<Loading />}><InventoryDashboard /></Suspense>,
    requiresAuth: true,
    allowedRoles: ['admin'],
  },
  {
    path: '/inventory/list',
    element: <Suspense fallback={<Loading />}><InventoryList /></Suspense>,
    requiresAuth: true,
    allowedRoles: ['admin'],
  },
  {
    path: '/inventory/add-item',
    element: <Suspense fallback={<Loading />}><AddInventoryItem /></Suspense>,
    requiresAuth: true,
    allowedRoles: ['admin'],
  },
  {
    path: '/inventory/edit/:id',
    element: <Suspense fallback={<Loading />}><EditInventoryItem /></Suspense>,
    requiresAuth: true,
    allowedRoles: ['admin'],
  },
  {
    path: '/inventory/item/:id',
    element: <Suspense fallback={<Loading />}><InventoryItemDetail /></Suspense>,
    requiresAuth: true,
    allowedRoles: ['admin'],
  },
];
