import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'sonner';
import { supabase } from '@/integrations/supabase/client';
import { Session } from '@supabase/supabase-js';
import { UserProfile, UserRole, AuthContextType } from '@/types/auth';
import { transformUserData, isSessionValid } from '@/utils/authUtils';
import { loginWithEmailPassword, signOut, createUser } from '@/utils/authActions';
import { handleAuthError } from '@/utils/supabaseErrorHandler';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<UserProfile | null>(null);
  const [session, setSession] = useState<Session | null>(null);
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const updateUserProfile = async (currentSession: Session | null) => {
    if (!currentSession) {
      setUser(null);
      return;
    }
    
    try {
      const userProfile = await transformUserData(currentSession);
      setUser(userProfile);
    } catch (error) {
      console.error('Failed to transform user data:', error);
      // Don't clear user here to prevent logout loops
    }
  };

  useEffect(() => {
    const initializeAuth = async () => {
      console.log('Initializing auth state...');
      setLoading(true);
      
      try {
        const { data: { subscription } } = supabase.auth.onAuthStateChange(
          async (event, newSession) => {
            console.log('Auth state changed:', event, newSession ? 'Session exists' : 'No session');
            
            if (event === 'SIGNED_OUT') {
              setSession(null);
              setUser(null);
              return;
            }
            
            if (newSession && isSessionValid(newSession)) {
              setSession(newSession);
              
              setTimeout(() => {
                updateUserProfile(newSession);
              }, 0);
            } else if (event === 'TOKEN_REFRESHED' && newSession) {
              setSession(newSession);
            }
          }
        );

        const { data: { session: currentSession }, error } = await supabase.auth.getSession();
        console.log('Existing session check:', currentSession ? 'Session found' : 'No session found');
        
        if (error) {
          console.error('Error getting session:', error);
          setSession(null);
          setUser(null);
        } else if (currentSession && isSessionValid(currentSession)) {
          setSession(currentSession);
          await updateUserProfile(currentSession);
        } else if (currentSession && !isSessionValid(currentSession)) {
          console.log('Session expired, refreshing...');
          const { data: { session: refreshedSession }, error: refreshError } = 
            await supabase.auth.refreshSession();
            
          if (refreshError || !refreshedSession) {
            console.error('Failed to refresh session:', refreshError);
            setSession(null);
            setUser(null);
          } else {
            setSession(refreshedSession);
            await updateUserProfile(refreshedSession);
          }
        }

        return () => {
          subscription.unsubscribe();
        };
      } catch (error) {
        console.error('Auth initialization error:', error);
        setSession(null);
        setUser(null);
      } finally {
        setLoading(false);
        setInitialized(true);
        console.log('Auth initialization complete');
      }
    };

    initializeAuth();
    
    const handleUnload = () => {
      if (session) {
        console.log('Window closing, logging out user');
        localStorage.removeItem('supabase.auth.token');
      }
    };

    window.addEventListener('beforeunload', handleUnload);
    
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  const login = async (emailOrUniqueId: string, password: string) => {
    setLoading(true);
    
    try {
      await loginWithEmailPassword(
        emailOrUniqueId, 
        password, 
        () => {
          const origin = location.state?.from?.pathname || '/dashboard';
          navigate(origin);
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    setLoading(true);
    try {
      await signOut(() => {
        setUser(null);
        setSession(null);
        navigate('/');
      });
    } finally {
      setLoading(false);
    }
  };

  const signUp = async (email: string, password: string, role: UserRole, firstName: string, lastName: string): Promise<void> => {
    try {
      setLoading(true);
      
      if (!user || user.role !== 'admin') {
        throw new Error('Only administrators can create new user accounts');
      }
      
      await createUser(email, password, role, firstName, lastName);
    } catch (error: any) {
      handleAuthError(error, 'Registration failed');
    } finally {
      setLoading(false);
    }
  };

  const checkAuth = () => {
    return !!user;
  };

  const requireAuth = (allowedRoles?: UserRole[]) => {
    if (!user) return false;
    
    if (allowedRoles && allowedRoles.length > 0) {
      return allowedRoles.includes(user.role);
    }
    
    return true;
  };

  return (
    <AuthContext.Provider 
      value={{ 
        user, 
        session,
        login, 
        logout, 
        signUp,
        createUser, 
        loading,
        isAuthenticated: !!user,
        checkAuth,
        requireAuth
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
