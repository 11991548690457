
import React from 'react';
import { Separator } from '@/components/ui/separator';

interface GalleryHeaderProps {
  title: string;
  description: string;
}

export const GalleryHeader = React.memo(function GalleryHeader({ 
  title, 
  description 
}: GalleryHeaderProps) {
  return (
    <div className="mb-8">
      <h1 className="text-3xl font-bold tracking-tight">{title}</h1>
      <p className="text-muted-foreground mt-2">
        {description}
      </p>
      <Separator className="my-4" />
    </div>
  );
});
