
import { toast } from 'sonner';
import { supabase } from '@/integrations/supabase/client';
import { UserRole } from '@/types/auth';
import { handleAuthError } from '@/utils/supabaseErrorHandler';

/**
 * Login with email/password or uniqueID/password
 */
export const loginWithEmailPassword = async (
  emailOrUniqueId: string, 
  password: string,
  onSuccess?: () => void,
  onError?: (error: Error) => void
): Promise<void> => {
  try {
    console.log('Attempting login with:', emailOrUniqueId);
    
    // Check if input looks like an email
    const isEmail = emailOrUniqueId.includes('@');
    
    let emailToUse = emailOrUniqueId;
    
    // If it doesn't look like an email, it's probably a uniqueId - try to find matching email
    if (!isEmail) {
      const { data, error } = await supabase
        .from('profiles')
        .select('id')
        .eq('unique_id', emailOrUniqueId)
        .single();
      
      if (error || !data) {
        console.error('Error finding user with uniqueId:', emailOrUniqueId, error);
        throw new Error('Invalid school ID or password');
      }
      
      // Get the email from auth.users table
      const { data: userData, error: userError } = await supabase.auth.admin.getUserById(data.id);
      
      if (userError || !userData?.user) {
        console.error('Error finding user email:', userError);
        throw new Error('Could not retrieve user information');
      }
      
      emailToUse = userData.user.email || '';
      
      if (!emailToUse) {
        throw new Error('User account does not have an associated email');
      }
    }
    
    // Proceed with normal login using email
    const { data, error } = await supabase.auth.signInWithPassword({
      email: emailToUse,
      password,
    });
    
    if (error) {
      throw error;
    }
    
    if (!data?.user) {
      throw new Error('Login succeeded but no user data returned');
    }
    
    console.log('User logged in successfully:', data.user.id);
    toast.success('Logged in successfully');
    
    if (onSuccess) {
      onSuccess();
    }
  } catch (error: any) {
    console.error('Login error:', error);
    
    // Handle specific error for unique ID not found
    if (error.message === 'Invalid school ID or password') {
      toast.error('Invalid school ID or password');
    } else {
      handleAuthError(error, 'Error logging in');
    }
    
    if (onError) {
      onError(error);
    } else {
      throw error;
    }
  }
};

/**
 * Sign out the current user
 */
export const signOut = async (onSuccess?: () => void): Promise<void> => {
  try {
    const { error } = await supabase.auth.signOut();
    
    if (error) {
      throw error;
    }
    
    console.log('User signed out successfully');
    toast.success('Signed out successfully');
    
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    console.error('Sign out error:', error);
    handleAuthError(error, 'Error signing out');
    throw error;
  }
};

/**
 * Create a new user account (admin only)
 */
export const createUser = async (
  email: string,
  password: string,
  role: UserRole,
  firstName: string,
  lastName: string
): Promise<void> => {
  try {
    // Create user in Supabase Auth
    const { data, error } = await supabase.auth.admin.createUser({
      email,
      password,
      email_confirm: true,
    });
    
    if (error) {
      throw error;
    }
    
    if (!data.user) {
      throw new Error('User created but no data returned');
    }
    
    // Create user profile in our profiles table
    const { error: profileError } = await supabase
      .from('profiles')
      .insert({
        id: data.user.id,
        first_name: firstName,
        last_name: lastName,
        role,
      });
    
    if (profileError) {
      // Try to delete the user if profile creation fails
      await supabase.auth.admin.deleteUser(data.user.id);
      throw profileError;
    }
    
    // Generate unique ID for the user
    try {
      const currentYear = new Date().getFullYear();
      
      const { data: uniqueId, error: genError } = await supabase
        .rpc('generate_unique_id', { 
          role: role,
          join_year: currentYear 
        });
        
      if (!genError && uniqueId) {
        await supabase
          .from('profiles')
          .update({ unique_id: uniqueId })
          .eq('id', data.user.id);
      }
    } catch (idError) {
      console.error('Error generating unique ID (non-fatal):', idError);
      // Don't throw here, as the user account is already created
    }
    
    console.log('User created successfully:', data.user.id);
    toast.success('User created successfully');
  } catch (error) {
    console.error('Create user error:', error);
    handleAuthError(error, 'Error creating user');
    throw error;
  }
};
