
import { useQuery } from '@tanstack/react-query';
import { GalleryAlbumWithItems } from '@/types/gallery';
import { fetchGalleryAlbumWithImages } from '@/services/galleryService';
import { toast } from 'sonner';

interface UseGalleryAlbumDetailsOptions {
  albumId: string;
  autoLoad?: boolean;
}

interface UseGalleryAlbumDetailsResult {
  album: GalleryAlbumWithItems | null;
  loading: boolean;
  error: Error | null;
  refreshAlbum: () => Promise<void>;
}

export function useGalleryAlbumDetails({
  albumId,
  autoLoad = true
}: UseGalleryAlbumDetailsOptions): UseGalleryAlbumDetailsResult {
  const {
    data: album,
    isLoading: loading,
    error,
    refetch
  } = useQuery({
    queryKey: ['album', albumId],
    queryFn: () => fetchGalleryAlbumWithImages(albumId),
    enabled: autoLoad && !!albumId,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 10 * 60 * 1000, // 10 minutes
    retry: 1,
    meta: {
      onError: (err: Error) => {
        console.error('Error loading album details:', err);
        toast.error('Failed to load album details');
      }
    }
  });

  const refreshAlbum = async () => {
    await refetch();
  };

  return {
    album: album || null,
    loading,
    error: error as Error | null,
    refreshAlbum
  };
}
