
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { routes } from './routes';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import { Toaster } from './components/ui/sonner';
import { GlobalErrorHandler } from './components/error/GlobalErrorHandler';
import { QueryClientProvider } from './context/QueryClientProvider';
import { GlobalStateProvider } from './context/GlobalStateContext';
import { EventsProvider } from './context/EventsContext';
import { AnnouncementProvider } from './context/AnnouncementContext';
import { AuthProvider } from './context/AuthContext';
import { NewsProvider } from './context/NewsContext';

function App() {
  return (
    <QueryClientProvider>
      <GlobalStateProvider>
        <GlobalErrorHandler>
          <AuthProvider>
            <EventsProvider>
              <AnnouncementProvider>
                <NewsProvider>
                  <Routes>
                    {/* Render all routes from the unified routes array */}
                    {routes.map((route) => (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={
                          route.requiresAuth ? (
                            <ProtectedRoute allowedRoles={route.allowedRoles}>
                              {route.element}
                            </ProtectedRoute>
                          ) : (
                            route.element
                          )
                        }
                      />
                    ))}
                  </Routes>
                  
                  <Toaster position="top-right" />
                </NewsProvider>
              </AnnouncementProvider>
            </EventsProvider>
          </AuthProvider>
        </GlobalErrorHandler>
      </GlobalStateProvider>
    </QueryClientProvider>
  );
}

export default App;
