
import React, { createContext, useContext, ReactNode } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { useAuth } from './AuthContext';
import { toast } from 'sonner';
import { News, NewsInsert, NewsUpdate } from '@/types/news';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

interface NewsContextType {
  newsItems: News[];
  featuredNews: News[];
  loading: boolean;
  error: string | null;
  createNewsItem: (newsData: Omit<NewsInsert, 'id' | 'created_by' | 'created_at' | 'updated_at'>) => Promise<void>;
  updateNewsItem: (id: string, newsData: Omit<NewsUpdate, 'id' | 'created_by' | 'updated_at'>) => Promise<void>;
  deleteNewsItem: (id: string) => Promise<void>;
  refreshNews: () => Promise<void>;
}

const NewsContext = createContext<NewsContextType | undefined>(undefined);

export const NewsProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const fetchNews = async (): Promise<News[]> => {
    const { data, error } = await supabase
      .from('news')
      .select('*')
      .order('published_at', { ascending: false });

    if (error) {
      throw error;
    }

    return data as News[] || [];
  };

  const { 
    data: newsItems = [], 
    isLoading: loading, 
    error,
    refetch 
  } = useQuery({
    queryKey: ['news'],
    queryFn: fetchNews,
    staleTime: 1000 * 60 * 5, // 5 minutes
    gcTime: 1000 * 60 * 15, // 15 minutes
  });

  // Filter featured news
  const featuredNews = newsItems?.filter(item => item.is_featured) || [];
  
  const createNewsItemMutation = useMutation({
    mutationFn: async (newsData: Omit<NewsInsert, 'id' | 'created_by' | 'created_at' | 'updated_at'>) => {
      if (!user) {
        throw new Error('You must be logged in to create a news item');
      }

      const { data, error } = await supabase
        .from('news')
        .insert([
          {
            ...newsData,
            created_by: user.id,
          },
        ] as any)
        .select();

      if (error) {
        throw error;
      }

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['news'] });
      toast.success('News item created successfully');
    },
    onError: (err: Error) => {
      console.error('Error creating news item:', err);
      toast.error(`Failed to create news item: ${err.message}`);
    }
  });

  const updateNewsItemMutation = useMutation({
    mutationFn: async ({ id, newsData }: { id: string, newsData: Omit<NewsUpdate, 'id' | 'created_by' | 'updated_at'> }) => {
      if (!user) {
        throw new Error('You must be logged in to update a news item');
      }

      const { data, error } = await supabase
        .from('news')
        .update({
          ...newsData,
          updated_at: new Date().toISOString(),
        } as any)
        .eq('id', id)
        .select();

      if (error) {
        throw error;
      }

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['news'] });
      toast.success('News item updated successfully');
    },
    onError: (err: Error) => {
      console.error('Error updating news item:', err);
      toast.error(`Failed to update news item: ${err.message}`);
    }
  });

  const deleteNewsItemMutation = useMutation({
    mutationFn: async (id: string) => {
      if (!user) {
        throw new Error('You must be logged in to delete a news item');
      }

      const { error } = await supabase
        .from('news')
        .delete()
        .eq('id', id);

      if (error) {
        throw error;
      }

      return id;
    },
    onSuccess: (deletedId) => {
      queryClient.setQueryData(['news'], (oldData: News[] | undefined) => 
        (oldData || []).filter(item => item.id !== deletedId)
      );
      toast.success('News item deleted successfully');
    },
    onError: (err: Error) => {
      console.error('Error deleting news item:', err);
      toast.error(`Failed to delete news item: ${err.message}`);
    }
  });

  const createNewsItem = async (newsData: Omit<NewsInsert, 'id' | 'created_by' | 'created_at' | 'updated_at'>): Promise<void> => {
    await createNewsItemMutation.mutateAsync(newsData);
  };

  const updateNewsItem = async (id: string, newsData: Omit<NewsUpdate, 'id' | 'created_by' | 'updated_at'>): Promise<void> => {
    await updateNewsItemMutation.mutateAsync({ id, newsData });
  };

  const deleteNewsItem = async (id: string): Promise<void> => {
    await deleteNewsItemMutation.mutateAsync(id);
  };

  const refreshNews = async (): Promise<void> => {
    await refetch();
  };

  return (
    <NewsContext.Provider
      value={{
        newsItems,
        featuredNews,
        loading,
        error: error ? (error as Error).message : null,
        createNewsItem,
        updateNewsItem,
        deleteNewsItem,
        refreshNews,
      }}
    >
      {children}
    </NewsContext.Provider>
  );
};

export const useNews = () => {
  const context = useContext(NewsContext);
  if (context === undefined) {
    throw new Error('useNews must be used within a NewsProvider');
  }
  return context;
};
