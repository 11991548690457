
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { GalleryImage } from '@/types/gallery';
import { getMediaUrl } from '@/services/galleryService';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useVirtualizedList } from '@/hooks/useVirtualizedList';

interface AlbumViewerProps {
  images: GalleryImage[];
}

const AlbumViewer = React.memo(function AlbumViewer({ images }: AlbumViewerProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);
  
  const currentImage = images?.[currentIndex];
  const THUMBNAIL_HEIGHT = 80; // height of each thumbnail
  
  const {
    virtualItems,
    totalHeight,
    containerRef,
    scrollToIndex
  } = useVirtualizedList({
    totalItems: images?.length || 0,
    itemHeight: THUMBNAIL_HEIGHT,
    overscan: 5,
  });
  
  // When current index changes, scroll to ensure it's visible
  useEffect(() => {
    scrollToIndex(currentIndex);
  }, [currentIndex, scrollToIndex]);
  
  const handleNext = useCallback(() => {
    if (!images?.length) return;
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, [images]);
  
  const handlePrev = useCallback(() => {
    if (!images?.length) return;
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  }, [images]);
  
  const handleToggleFullScreen = useCallback(() => {
    setFullScreen(prev => !prev);
  }, []);
  
  const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'ArrowRight') handleNext();
    else if (e.key === 'ArrowLeft') handlePrev();
    else if (e.key === 'Escape') setFullScreen(false);
  }, [handleNext, handlePrev]);

  const handleThumbnailClick = useCallback((index: number) => {
    setCurrentIndex(index);
  }, []);

  if (!images?.length) {
    return (
      <div className="flex flex-col items-center justify-center p-12 text-center">
        <p className="text-lg text-muted-foreground">This album is empty</p>
      </div>
    );
  }

  return (
    <div 
      className="w-full" 
      tabIndex={0} 
      onKeyDown={handleKeyDown}
    >
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="md:col-span-3">
          <div className={`relative ${fullScreen ? 'fixed inset-0 z-50 bg-black flex items-center justify-center' : 'aspect-video bg-black'}`}>
            <img
              src={getMediaUrl(currentImage.imageUrl)}
              alt={currentImage.title || `Image ${currentIndex + 1}`}
              className={`${fullScreen ? 'max-h-screen max-w-full object-contain' : 'w-full h-full object-contain'}`}
            />
            
            {fullScreen && (
              <Button
                variant="ghost"
                size="icon"
                className="absolute top-4 right-4 bg-black/20 text-white hover:bg-black/40"
                onClick={handleToggleFullScreen}
              >
                <X className="h-6 w-6" />
              </Button>
            )}
            
            <Button
              variant="ghost"
              size="icon"
              className="absolute left-2 top-1/2 -translate-y-1/2 bg-black/20 text-white hover:bg-black/40"
              onClick={handlePrev}
            >
              <ChevronLeft className="h-6 w-6" />
            </Button>
            
            <Button
              variant="ghost"
              size="icon"
              className="absolute right-2 top-1/2 -translate-y-1/2 bg-black/20 text-white hover:bg-black/40"
              onClick={handleNext}
            >
              <ChevronRight className="h-6 w-6" />
            </Button>
          </div>
          
          <div className="mt-2 px-1">
            <h3 className="text-lg font-medium">{currentImage.title || `Image ${currentIndex + 1}`}</h3>
            {currentImage.description && (
              <p className="text-muted-foreground">{currentImage.description}</p>
            )}
            <div className="flex justify-between items-center mt-2">
              <div className="text-sm text-muted-foreground">
                {currentIndex + 1} of {images.length}
              </div>
              <Button 
                variant="outline" 
                size="sm" 
                onClick={handleToggleFullScreen}
              >
                {fullScreen ? 'Exit Fullscreen' : 'View Fullscreen'}
              </Button>
            </div>
          </div>
        </div>
        
        <div className="md:col-span-1 h-[60vh]">
          <ScrollArea className="h-full" ref={containerRef}>
            <div 
              className="relative" 
              style={{ height: totalHeight }}
            >
              {virtualItems.map(virtualItem => (
                <ThumbnailImage 
                  key={virtualItem.index}
                  image={images[virtualItem.index]}
                  index={virtualItem.index}
                  isActive={virtualItem.index === currentIndex}
                  onClick={handleThumbnailClick}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: THUMBNAIL_HEIGHT,
                    transform: `translateY(${virtualItem.index * THUMBNAIL_HEIGHT}px)`,
                  }}
                />
              ))}
            </div>
          </ScrollArea>
        </div>
      </div>
    </div>
  );
});

// Extract Thumbnail into a separate component for better performance
interface ThumbnailImageProps {
  image: GalleryImage;
  index: number;
  isActive: boolean;
  onClick: (index: number) => void;
  style?: React.CSSProperties;
}

const ThumbnailImage = React.memo(function ThumbnailImage({ 
  image, 
  index, 
  isActive, 
  onClick,
  style 
}: ThumbnailImageProps) {
  const handleClick = useCallback(() => {
    onClick(index);
  }, [onClick, index]);

  return (
    <div 
      className={`cursor-pointer overflow-hidden transition-all ${
        isActive 
          ? 'ring-2 ring-primary' 
          : 'opacity-80 hover:opacity-100'
      }`}
      onClick={handleClick}
      style={style}
    >
      <div className="w-full h-full p-1">
        <img 
          src={getMediaUrl(image.thumbnailUrl || image.imageUrl)} 
          alt={image.title || `Thumbnail ${index + 1}`}
          className="w-full h-full object-cover"
          loading={index < 20 ? "eager" : "lazy"}
        />
      </div>
    </div>
  );
});

export default AlbumViewer;
