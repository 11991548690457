
import { useState, useEffect, useRef, useCallback } from 'react';

interface VirtualizedItem {
  index: number;
  isVisible: boolean;
}

interface UseVirtualizedListOptions {
  totalItems: number;
  itemHeight: number;
  overscan?: number;
  containerHeight?: number;
}

interface UseVirtualizedListResult {
  virtualItems: VirtualizedItem[];
  totalHeight: number;
  containerRef: React.RefObject<HTMLDivElement>;
  scrollToIndex: (index: number) => void;
}

export function useVirtualizedList({
  totalItems,
  itemHeight,
  overscan = 5,
  containerHeight = 0
}: UseVirtualizedListOptions): UseVirtualizedListResult {
  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleRange, setVisibleRange] = useState({ start: 0, end: 0 });

  const totalHeight = totalItems * itemHeight;

  // Calculate which items should be visible based on scroll position
  const calculateVisibleItems = useCallback(() => {
    if (!containerRef.current) return;
    
    const { offsetHeight, scrollTop } = containerRef.current;
    const safeContainerHeight = offsetHeight || containerHeight;
    
    const startIndex = Math.max(0, Math.floor(scrollTop / itemHeight) - overscan);
    const endIndex = Math.min(
      totalItems - 1,
      Math.ceil((scrollTop + safeContainerHeight) / itemHeight) + overscan
    );
    
    setVisibleRange({ start: startIndex, end: endIndex });
  }, [itemHeight, totalItems, overscan, containerHeight]);

  // Handle scroll events
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    calculateVisibleItems();
    
    const handleScroll = () => {
      requestAnimationFrame(calculateVisibleItems);
    };
    
    container.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    
    return () => {
      container.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [calculateVisibleItems]);

  // Create the array of virtual items
  const virtualItems = Array.from({ length: visibleRange.end - visibleRange.start + 1 }, 
    (_, i) => ({
      index: visibleRange.start + i,
      isVisible: true
    })
  );

  // Scroll to a specific index
  const scrollToIndex = useCallback((index: number) => {
    if (containerRef.current) {
      containerRef.current.scrollTop = index * itemHeight;
    }
  }, [itemHeight]);

  return {
    virtualItems,
    totalHeight,
    containerRef,
    scrollToIndex
  };
}
