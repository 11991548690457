
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import { Loader2 } from 'lucide-react';
import { toast } from 'sonner';

interface ProtectedRouteProps {
  children: React.ReactNode;
  allowedRoles?: ('teacher' | 'student' | 'admin')[];
}

export const ProtectedRoute = ({ 
  children, 
  allowedRoles = [] 
}: ProtectedRouteProps) => {
  const { user, loading, isAuthenticated, session } = useAuth();
  const location = useLocation();
  
  // Effect for debugging auth state
  useEffect(() => {
    console.log('ProtectedRoute auth state:', { 
      isAuthenticated, 
      loading, 
      hasUser: !!user, 
      hasSession: !!session,
      currentPath: location.pathname
    });
  }, [isAuthenticated, loading, user, session, location.pathname]);

  // Show loading state when checking authentication
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[60vh]">
        <div className="flex flex-col items-center space-y-4">
          <Loader2 className="h-12 w-12 animate-spin text-primary" />
          <p className="text-lg text-muted-foreground">Checking authentication...</p>
        </div>
      </div>
    );
  }

  // Redirect to login if not authenticated
  if (!isAuthenticated || !user) {
    console.log('User not authenticated, redirecting to login from:', location.pathname);
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Check role permissions if specified
  if (allowedRoles.length > 0 && user && !allowedRoles.includes(user.role)) {
    // Notify user about insufficient permissions
    toast.error(`Access denied. This area requires ${allowedRoles.join(' or ')} permissions.`);
    console.log('Insufficient permissions for user:', user.role, 'Required:', allowedRoles);
    
    // Redirect to dashboard on insufficient permissions
    return <Navigate to="/dashboard" replace />;
  }

  // If authenticated and authorized, render the children
  console.log('User authenticated and authorized:', user?.role);
  return <>{children}</>;
};
