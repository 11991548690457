
import React from 'react';
import { Skeleton } from '@/components/ui/skeleton';

export interface SkeletonCardProps {
  count?: number;
  height?: string;
  width?: string;
  className?: string;
}

export const SkeletonCard = ({ 
  count = 1, 
  height = 'h-48', 
  width = 'w-full', 
  className = '' 
}: SkeletonCardProps) => {
  return (
    <>
      {Array(count).fill(0).map((_, index) => (
        <div key={index} className={`space-y-3 ${width} ${className}`}>
          <Skeleton className={`${height} rounded-lg`} />
          <Skeleton className="h-4 w-3/4" />
          <Skeleton className="h-3 w-1/2" />
        </div>
      ))}
    </>
  );
};

export interface SkeletonTableProps {
  rows?: number;
  columns?: number;
}

export const SkeletonTable = ({ rows = 5, columns = 4 }: SkeletonTableProps) => {
  return (
    <div className="w-full">
      {/* Header */}
      <div className="grid grid-cols-12 gap-2 mb-4 p-4 border-b">
        {Array(columns).fill(0).map((_, index) => (
          <Skeleton 
            key={`header-${index}`} 
            className={`h-6 ${index === 0 ? 'col-span-1' : index === 1 ? 'col-span-5' : 'col-span-2'}`} 
          />
        ))}
      </div>
      
      {/* Body */}
      {Array(rows).fill(0).map((_, rowIndex) => (
        <div key={`row-${rowIndex}`} className="grid grid-cols-12 gap-2 p-4 border-b">
          {Array(columns).fill(0).map((_, colIndex) => (
            <Skeleton 
              key={`cell-${rowIndex}-${colIndex}`} 
              className={`h-6 ${colIndex === 0 ? 'col-span-1' : colIndex === 1 ? 'col-span-5' : 'col-span-2'}`} 
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export interface SkeletonProfileProps {
  includeAvatar?: boolean;
}

export const SkeletonProfile = ({ includeAvatar = true }: SkeletonProfileProps) => {
  return (
    <div className="space-y-4 p-4">
      {includeAvatar && (
        <div className="flex items-center mb-6">
          <Skeleton className="h-16 w-16 rounded-full" />
          <div className="ml-4 space-y-2 w-full max-w-xs">
            <Skeleton className="h-5 w-32" />
            <Skeleton className="h-4 w-24" />
          </div>
        </div>
      )}
      
      <div className="space-y-2">
        <Skeleton className="h-5 w-40" />
        <Skeleton className="h-8 w-full" />
      </div>
      
      <div className="space-y-2">
        <Skeleton className="h-5 w-32" />
        <Skeleton className="h-8 w-full" />
      </div>
      
      <div className="space-y-2">
        <Skeleton className="h-5 w-36" />
        <Skeleton className="h-8 w-full" />
      </div>
    </div>
  );
};

export interface SkeletonStatProps {
  count?: number;
}

export const SkeletonStats = ({ count = 4 }: SkeletonStatProps) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      {Array(count).fill(0).map((_, index) => (
        <div key={index} className="p-4 border rounded-lg space-y-3">
          <Skeleton className="h-5 w-20" />
          <Skeleton className="h-8 w-24" />
          <Skeleton className="h-3 w-full" />
        </div>
      ))}
    </div>
  );
};
