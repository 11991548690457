
import { supabase } from '@/integrations/supabase/client';
import { 
  GalleryAlbum, 
  GalleryImage, 
  GalleryVideo, 
  GalleryAlbumWithItems 
} from '@/types/gallery';
import { 
  transformGalleryAlbum, 
  transformGalleryImage, 
  transformGalleryVideo,
  transformGalleryAlbumWithImages
} from '@/utils/dataTransformUtils';

// Media URL helper functions
export const getMediaUrl = (url: string | null | undefined): string => {
  if (!url) return '/placeholder.svg';
  if (url.startsWith('http')) return url;
  return `${url}`;
};

export const getImageUrl = getMediaUrl;
export const getVideoUrl = getMediaUrl;

// Album functions with pagination
interface PaginationOptions {
  limit?: number;
  page?: number;
}

interface PaginatedResult<T> {
  data: T[];
  totalCount: number;
  hasMore: boolean;
}

export const fetchGalleryAlbums = async (options: PaginationOptions = {}): Promise<{
  albums: GalleryAlbum[];
  totalCount: number;
  hasMore: boolean;
}> => {
  try {
    const { limit = 12, page = 1 } = options;
    const offset = (page - 1) * limit;
    
    // First get the total count
    const { count, error: countError } = await supabase
      .from('gallery_albums')
      .select('*', { count: 'exact', head: true });
    
    if (countError) throw countError;
    
    // Then get the paginated data
    const { data, error } = await supabase
      .from('gallery_albums')
      .select('*')
      .order('updated_at', { ascending: false })
      .range(offset, offset + limit - 1);
    
    if (error) throw error;
    
    const totalCount = count || 0;
    const hasMore = offset + limit < totalCount;
    
    return {
      albums: data.map(transformGalleryAlbum),
      totalCount,
      hasMore
    };
  } catch (error) {
    console.error('Error fetching gallery albums:', error);
    throw error;
  }
};

export const fetchGalleryAlbumWithImages = async (albumId: string): Promise<GalleryAlbumWithItems> => {
  try {
    // First get the album details
    const { data: albumData, error: albumError } = await supabase
      .from('gallery_albums')
      .select('*')
      .eq('id', albumId)
      .single();
      
    if (albumError) throw albumError;
    
    // Then get all images for this album
    const { data: imagesData, error: imagesError } = await supabase
      .from('gallery_images')
      .select('*')
      .eq('album_id', albumId)
      .order('sort_order', { ascending: true });
      
    if (imagesError) throw imagesError;
    
    return transformGalleryAlbumWithImages(albumData, imagesData || []);
  } catch (error) {
    console.error('Error fetching gallery album with images:', error);
    throw error;
  }
};

// Image functions with pagination
export const fetchGalleryImages = async (albumId?: string, options: PaginationOptions = {}): Promise<{
  images: GalleryImage[];
  totalCount: number;
  hasMore: boolean;
}> => {
  try {
    const { limit = 20, page = 1 } = options;
    const offset = (page - 1) * limit;
    
    // Get the total count first
    let countQuery = supabase
      .from('gallery_images')
      .select('*', { count: 'exact', head: true });
    
    if (albumId) {
      countQuery = countQuery.eq('album_id', albumId);
    }
    
    const { count, error: countError } = await countQuery;
    
    if (countError) throw countError;
    
    // Then get the paginated data
    let dataQuery = supabase
      .from('gallery_images')
      .select('*')
      .order('sort_order', { ascending: true });
    
    if (albumId) {
      dataQuery = dataQuery.eq('album_id', albumId);
    }
    
    const { data, error } = await dataQuery.range(offset, offset + limit - 1);
    
    if (error) throw error;
    
    const totalCount = count || 0;
    const hasMore = offset + limit < totalCount;
    
    return {
      images: (data || []).map(transformGalleryImage),
      totalCount,
      hasMore
    };
  } catch (error) {
    console.error('Error fetching gallery images:', error);
    throw error;
  }
};

// Video functions with pagination
export const fetchGalleryVideos = async (options: PaginationOptions = {}): Promise<{
  videos: GalleryVideo[];
  totalCount: number;
  hasMore: boolean;
}> => {
  try {
    const { limit = 12, page = 1 } = options;
    const offset = (page - 1) * limit;
    
    // Get the total count first
    const { count, error: countError } = await supabase
      .from('gallery_videos')
      .select('*', { count: 'exact', head: true });
    
    if (countError) throw countError;
    
    // Then get the paginated data
    const { data, error } = await supabase
      .from('gallery_videos')
      .select('*')
      .order('updated_at', { ascending: false })
      .range(offset, offset + limit - 1);
    
    if (error) throw error;
    
    const totalCount = count || 0;
    const hasMore = offset + limit < totalCount;
    
    return {
      videos: (data || []).map(transformGalleryVideo),
      totalCount,
      hasMore
    };
  } catch (error) {
    console.error('Error fetching gallery videos:', error);
    throw error;
  }
};
