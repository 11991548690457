
import { RouteConfig } from './types';
import { publicRoutes } from './publicRoutes';
import { authRoutes } from './authRoutes';
import { adminRoutes } from './adminRoutes';
import { feeRoutes } from './feeRoutes';
import { inventoryRoutes } from './inventoryRoutes';

// Combine all routes with proper typing
export const routes: RouteConfig[] = [
  ...publicRoutes,
  ...authRoutes,
  ...adminRoutes.map(route => ({
    ...route,
    requiresAuth: true,
    allowedRoles: route.allowedRoles || ['admin']
  })),
  ...feeRoutes.map(route => ({
    ...route,
    requiresAuth: true,
    allowedRoles: route.allowedRoles || ['admin', 'teacher']
  })),
  ...inventoryRoutes.map(route => ({
    ...route,
    requiresAuth: true,
    allowedRoles: route.allowedRoles || ['admin']
  }))
];
