
import React, { ReactNode, memo } from 'react';
import { Navbar } from './Navbar';
import { Footer } from './Footer';
import { AnnouncementBanner } from './AnnouncementBanner';

type LayoutProps = {
  children: ReactNode;
};

// Using memo to prevent unnecessary Layout re-renders
export const Layout = memo(function Layout({ children }: LayoutProps) {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="sticky top-0 z-50">
        <AnnouncementBanner />
        <Navbar />
      </div>
      <main className="flex-grow">
        {children}
      </main>
      <Footer />
    </div>
  );
});
