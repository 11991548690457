
import React from 'react';
import { Layout } from '@/components/layout/Layout';
import { Card } from '@/components/ui-components/Card';
import { MapPin, Mail, Phone, School, FileText, User } from 'lucide-react';

const SchoolDetails = () => {
  return (
    <Layout>
      <section className="py-12 bg-gradient-to-r from-blue-50 to-green-50 dark:from-blue-950 dark:to-green-950">
        <div className="container px-4 md:px-6">
          <div className="flex flex-col items-center text-center mb-10">
            <h1 className="text-3xl md:text-4xl font-display font-bold mb-4">School Details</h1>
            <p className="text-muted-foreground max-w-3xl">
              Official information about St. Thomas Higher Secondary School
            </p>
          </div>
          
          <Card className="max-w-4xl mx-auto p-6 md:p-8">
            <div className="flex flex-col items-center mb-8">
              <img 
                src="/lovable-uploads/f6ce99c3-7765-4d7e-8dd3-df7d44a04500.png" 
                alt="St. Thomas Higher Secondary School Logo" 
                className="h-24 w-24 object-contain mb-4"
              />
              <h2 className="text-2xl font-display font-bold text-center">St. Thomas Higher Secondary School</h2>
              <p className="text-sm italic text-muted-foreground mt-1">"Light To Life"</p>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="flex gap-3">
                <School className="h-5 w-5 text-primary mt-0.5 shrink-0" />
                <div>
                  <h3 className="font-medium text-sm text-muted-foreground mb-1">School Name</h3>
                  <p className="font-medium">St. Thomas Higher Secondary School</p>
                </div>
              </div>
              
              <div className="flex gap-3">
                <FileText className="h-5 w-5 text-primary mt-0.5 shrink-0" />
                <div>
                  <h3 className="font-medium text-sm text-muted-foreground mb-1">Board/Affiliation Number</h3>
                  <p className="font-medium">NBSE/ PR/5311/19</p>
                </div>
              </div>
              
              <div className="flex gap-3">
                <MapPin className="h-5 w-5 text-primary mt-0.5 shrink-0" />
                <div>
                  <h3 className="font-medium text-sm text-muted-foreground mb-1">Complete Address</h3>
                  <p className="font-medium">Naga Gaon Colony, Signal Basti, Dimapur-797118</p>
                </div>
              </div>
              
              <div className="flex gap-3">
                <User className="h-5 w-5 text-primary mt-0.5 shrink-0" />
                <div>
                  <h3 className="font-medium text-sm text-muted-foreground mb-1">Principal</h3>
                  <p className="font-medium">Mrs. Mini Aravind</p>
                </div>
              </div>
              
              <div className="flex gap-3">
                <Mail className="h-5 w-5 text-primary mt-0.5 shrink-0" />
                <div>
                  <h3 className="font-medium text-sm text-muted-foreground mb-1">School Email ID</h3>
                  <a href="mailto:stthomasnagagaon@gmail.com" className="font-medium text-primary hover:underline">
                    stthomasnagagaon@gmail.com
                  </a>
                </div>
              </div>
              
              <div className="flex gap-3">
                <Phone className="h-5 w-5 text-primary mt-0.5 shrink-0" />
                <div>
                  <h3 className="font-medium text-sm text-muted-foreground mb-1">Contact Details</h3>
                  <a href="tel:+919862589089" className="font-medium text-primary hover:underline">
                    +91 9862589089
                  </a>
                </div>
              </div>
            </div>
            
            <div className="mt-10 pt-6 border-t border-border">
              <h3 className="text-xl font-medium mb-4">Our Team</h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="flex flex-col items-center text-center">
                  <img 
                    src="/lovable-uploads/fc6297f4-cabe-4343-8e56-e612adb74466.png" 
                    alt="Mrs. Sobha Sreekumar" 
                    className="h-24 w-24 rounded-full object-cover mb-3"
                  />
                  <h4 className="font-medium">Mrs. Sobha Sreekumar</h4>
                  <p className="text-sm text-muted-foreground">Chairman of the Trust & Co-Founder</p>
                </div>
                
                <div className="flex flex-col items-center text-center">
                  <img 
                    src="/lovable-uploads/887e732a-5304-4a51-a4bb-b72dabc8aa08.png" 
                    alt="Mrs. Mini Aravind" 
                    className="h-24 w-24 rounded-full object-cover mb-3"
                  />
                  <h4 className="font-medium">Mrs. Mini Aravind</h4>
                  <p className="text-sm text-muted-foreground">Principal</p>
                </div>
                
                <div className="flex flex-col items-center text-center">
                  <div className="h-24 w-24 rounded-full bg-primary/10 flex items-center justify-center mb-3">
                    <User className="h-12 w-12 text-primary/50" />
                  </div>
                  <h4 className="font-medium">Rahul</h4>
                  <p className="text-sm text-muted-foreground">Administrator</p>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </section>
    </Layout>
  );
};

export default SchoolDetails;
