
import React, { createContext, useContext, ReactNode } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { useAuth } from './AuthContext';
import { toast } from 'sonner';
import { Event, EventInsert, EventUpdate } from '@/types/events';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

interface EventsContextType {
  events: Event[];
  featuredEvents: Event[];
  loading: boolean;
  error: string | null;
  createEvent: (eventData: Omit<EventInsert, 'id' | 'created_by' | 'created_at' | 'updated_at'>) => Promise<void>;
  updateEvent: (id: string, eventData: Omit<EventUpdate, 'id' | 'created_by' | 'updated_at'>) => Promise<void>;
  deleteEvent: (id: string) => Promise<void>;
  refreshEvents: () => Promise<void>;
}

const EventsContext = createContext<EventsContextType | undefined>(undefined);

export const EventsProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const fetchEvents = async (): Promise<Event[]> => {
    const { data, error } = await supabase
      .from('events')
      .select('*')
      .order('event_date', { ascending: true });

    if (error) {
      throw error;
    }

    return data as Event[] || [];
  };

  const { 
    data: events = [], 
    isLoading: loading, 
    error,
    refetch 
  } = useQuery({
    queryKey: ['events'],
    queryFn: fetchEvents,
    staleTime: 1000 * 60 * 5, // 5 minutes
    gcTime: 1000 * 60 * 15, // 15 minutes
  });

  // Filter featured events
  const featuredEvents = events?.filter(event => event.is_featured) || [];
  
  const createEventMutation = useMutation({
    mutationFn: async (eventData: Omit<EventInsert, 'id' | 'created_by' | 'created_at' | 'updated_at'>) => {
      if (!user) {
        throw new Error('You must be logged in to create an event');
      }

      const { data, error } = await supabase
        .from('events')
        .insert([
          {
            ...eventData,
            created_by: user.id,
          },
        ] as any)
        .select();

      if (error) {
        throw error;
      }

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['events'] });
      toast.success('Event created successfully');
    },
    onError: (err: Error) => {
      console.error('Error creating event:', err);
      toast.error(`Failed to create event: ${err.message}`);
    }
  });

  const updateEventMutation = useMutation({
    mutationFn: async ({ id, eventData }: { id: string, eventData: Omit<EventUpdate, 'id' | 'created_by' | 'updated_at'> }) => {
      if (!user) {
        throw new Error('You must be logged in to update an event');
      }

      const { data, error } = await supabase
        .from('events')
        .update({
          ...eventData,
          updated_at: new Date().toISOString(),
        } as any)
        .eq('id', id)
        .select();

      if (error) {
        throw error;
      }

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['events'] });
      toast.success('Event updated successfully');
    },
    onError: (err: Error) => {
      console.error('Error updating event:', err);
      toast.error(`Failed to update event: ${err.message}`);
    }
  });

  const deleteEventMutation = useMutation({
    mutationFn: async (id: string) => {
      if (!user) {
        throw new Error('You must be logged in to delete an event');
      }

      const { error } = await supabase
        .from('events')
        .delete()
        .eq('id', id);

      if (error) {
        throw error;
      }

      return id;
    },
    onSuccess: (deletedId) => {
      queryClient.setQueryData(['events'], (oldData: Event[] | undefined) => 
        (oldData || []).filter(event => event.id !== deletedId)
      );
      toast.success('Event deleted successfully');
    },
    onError: (err: Error) => {
      console.error('Error deleting event:', err);
      toast.error(`Failed to delete event: ${err.message}`);
    }
  });

  const createEvent = async (eventData: Omit<EventInsert, 'id' | 'created_by' | 'created_at' | 'updated_at'>): Promise<void> => {
    await createEventMutation.mutateAsync(eventData);
  };

  const updateEvent = async (id: string, eventData: Omit<EventUpdate, 'id' | 'created_by' | 'updated_at'>): Promise<void> => {
    await updateEventMutation.mutateAsync({ id, eventData });
  };

  const deleteEvent = async (id: string): Promise<void> => {
    await deleteEventMutation.mutateAsync(id);
  };

  const refreshEvents = async (): Promise<void> => {
    await refetch();
  };

  return (
    <EventsContext.Provider
      value={{
        events,
        featuredEvents,
        loading,
        error: error ? (error as Error).message : null,
        createEvent,
        updateEvent,
        deleteEvent,
        refreshEvents,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};

export const useEvents = () => {
  const context = useContext(EventsContext);
  if (context === undefined) {
    throw new Error('useEvents must be used within an EventsProvider');
  }
  return context;
};
